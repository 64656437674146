import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Canvas from "./NewIcon";
import {
  GroupsInRowsFromListScenario,
  RowsFromListScenario,
} from "../../redux/actions/actions/actionTypes";
import { DColumns } from "../../redux/actions/data/DataDeclarations";
import { State } from "../../redux/slices";
import { fetchScenarioInstance } from "../../redux/actions/actions/actionsAsyncActions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setActionId } from "../../redux/slices/fleet/actions";
import { IconButton, Tooltip } from "@mui/material";
import { inarixColors } from "inarix-theme";
import { v4 as uuidv4 } from "uuid";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setIndividualAction: (
      token: string,
      scenarioInstanceId: number,
      actionId: number,
      navigate: any
    ) => {
      dispatch(fetchScenarioInstance({ token: token, id: scenarioInstanceId }));
      dispatch(setActionId(actionId));
      if (actionId) {
        navigate(`/actions/${actionId}`);
      }
    },
  };
};

const manageStatusConstantId = (
  valueActiveNoActive: any,
  active: number[],
  notActive: number[],
  t: (arg0: string) => string
) => {
  if (active.filter((x) => x === valueActiveNoActive).length > 0) {
    return (
      <span className="active-table">
        {t("Fleet_users_actions.table.status_active")}
      </span>
    );
  }
  if (notActive.filter((x) => x === valueActiveNoActive).length > 0) {
    return (
      <span className="archive-table">
        {t("Fleet_users_actions.table.status_archived")}
      </span>
    );
  }
};

const manageScenarioName = (row: any, t: (arg0: string) => string) => {
  if (row.scenarioName === "null") {
    return t("Common_options.null");
  } else {
    return row.scenarioName;
  }
};

const manageScenarioDescription = (row: any, t: (arg0: string) => string) => {
  if (row.scenarioDescription === "null") {
    return t("Common_options.null");
  } else {
    return row.scenarioDescription;
  }
};

const ActionsUsersContent = (token: string) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const { setIndividualAction } = SetActions();
  const { actionsRows } = useSelector((state: State) => state.ActionsReducer);

  const columnNames = {
    scenarioIcon: t("Fleet_actions.table.Icon"),
    id: t("Fleet_actions.table.Id"),
    scenarioName: t("Fleet_actions.table.Scenario_name"),
    scenarioDescription: t("Fleet_actions.table.Description"),
    cerealName: t("Fleet_actions.table.Localised"),
    status: t("Fleet_actions.table.Scenario_status"),
    statusConstantId: t("Fleet_actions.table.Scenario_status"),
    group: t("Fleet_actions.table.Group"),
    details: t("Fleet_actions.table.Details"),
  };

  const rawColumns = [
    {
      columnField: "scenarioIcon",
      sortable: false,
    },
    {
      columnField: "id",
      sortable: true,
      width: 100,
    },

    {
      columnField: "scenarioName",
      sortable: true,
      width: 200,
    },
    {
      columnField: "scenarioDescription",
      sortable: false,
      width: 200,
    },
    {
      columnField: "cerealName",
      sortable: false,
    },
    {
      columnField: "status",
      sortable: false,
    },
    {
      columnField: "group",
      sortable: false,
      width: 200,
    },
    {
      columnField: "details",
      sortable: false,
      width: 100,
    },
  ] as DColumns[];

  let columns: any[] = rawColumns.map((column: DColumns) => {
    if (column.columnField === "scenarioIcon") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 60,
        sortable: false,

        renderCell: (params: GridRenderCellParams) => {
          const iconSaltNumber = params.formattedValue.iconSalt;
          const hashSlug = params.formattedValue.hashSlug;
          const background = params.formattedValue.background;
          return (
            <Canvas
              iconSalt={iconSaltNumber}
              slug={hashSlug}
              background={background}
              foregroundColor={background}
            />
          );
        },
      };
    }

    if (
      column.columnField === "statusConstantId" ||
      column.columnField === "status"
    ) {
      return {
        field: column.columnField,
        headerName: (columnNames as never)[column.columnField],
        width: 70,
        sortable: column.sortable,
        renderCell: (params: GridRenderCellParams) => {
          const valueActiveNoActive = params?.value;
          const active = [1, 35];
          const notActive = [2, 34];
          return manageStatusConstantId(
            valueActiveNoActive,
            active,
            notActive,
            t
          );
        },
      };
    }

    if (column.columnField === "group") {
      return {
        field: column.columnField,
        headerName: (columnNames as never)[column.columnField],
        width: column.width,
        sortable: column.sortable,
        renderCell: (params: GridRenderCellParams) => {
          const groupList = params?.value;
          const groups = groupList?.map((p: string) => {
            return (
              <div key={`${p}${uuidv4()}`} className="d-flex">
                <Chip
                  label={p}
                  variant="outlined"
                  key={`${p}${uuidv4()}`}
                  className={` d-flex ml-1`}
                />
              </div>
            );
          });
          return (
            <div style={{ display: "flex", flexWrap: "wrap" }}>{groups}</div>
          );
        },
      };
    }

    if (column.columnField === "details") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const action = params.formattedValue;

          const chipIdDevice = () => {
            const idConsole = () => {
              setIndividualAction(
                token,
                action?.lastScenarioInstance,
                action?.scenarioId,
                navigate
              );
            };
            return (
              <Tooltip title={t("Fleet_actions.table.Details" as string)} arrow>
                <IconButton
                  sx={{
                    color: inarixColors.main_inarix,
                    background: "#ffff",
                    boxShadow: `0 5px 15px -5px ${inarixColors.box_shadow_components}`,
                    marginRight: "5px",
                    "&:hover": {
                      color: inarixColors.light_inarix,
                    },
                  }}
                  onClick={idConsole}
                  aria-label="delete"
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            );
          };
          return chipIdDevice();
        },
      };
    }

    return {
      field: column.columnField,
      headerName: (columnNames as never)[column.columnField],
      width: column.width,
      sortable: column.sortable,
    };
  });

  let actionsRowsTransform = actionsRows?.map((row: RowsFromListScenario) => {
    const iconProps = {
      name: row.scenarioIcon.name,
      iconSalt: row.scenarioIcon.iconSalt,
      hashSlug: row.scenarioIcon?.hashSlug,
      background: row.scenarioIcon?.background,
      foreground: row.scenarioIcon?.foreground,
    };

    const scenarioProps = {
      lastScenarioInstance: row.lastScenarioInstanceId,
      scenarioId: row.id,
    };

    const scenarioGroups = row?.group?.map(
      (l: GroupsInRowsFromListScenario) => l.name
    );

    const rows = {
      scenarioIcon: iconProps,
      id: row.id,
      scenarioName: manageScenarioName(row, t),
      scenarioDescription: manageScenarioDescription(row, t),
      cerealName: row.cerealName?.toLowerCase().toLocaleLowerCase(),
      status: row.status,
      group: scenarioGroups,
      details: scenarioProps,
      lastScenarioInstanceId: row.lastScenarioInstanceId,
    };
    return rows;
  });

  if (!columns) {
    columns = [];
  }
  if (!actionsRowsTransform) {
    actionsRowsTransform = [];
  }

  return {
    columns,
    actionsRowsTransform,
  };
};

export default ActionsUsersContent;
